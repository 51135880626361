import { useBexExperiment } from './BexHooks'

interface ExperimentProps {
  experimentKey: string
}

const withBex =
  (experimentProps: ExperimentProps) =>
  <P extends object>(Component: React.FC<P>) =>
    function WrapExperiment(props: P) {
      const { experimentKey } = experimentProps

      const { isTreatmentGroup } = useBexExperiment(experimentKey)

      if (!isTreatmentGroup) return null

      return <Component {...props} />
    }

export { withBex }
