import { DailyDeliveryStatus } from '@apps/carepartner/interfaces/daily_delivery'

import { queryApi } from '@lib/query/api.config'
import { QuestionProps } from '@components/QuestionAndChoices'

import { CarepartnerUser } from '../../interfaces/user'

export type Explanation = {
  id: number
  standardTextPage: number
  text: string
}

export const getQuestionByNumber = async (number: number) => {
  return await queryApi('/question', { number: number })
}

export const getQuestionById = (id: number) => async () => {
  return await queryApi(`/question/${id}`)
}

export const getQuestionGroupById = async (id: number) => {
  return await queryApi(`/question-group/${id}`)
}

export const getAllQuestionGroup = async () => {
  return await queryApi(`/question-group`)
}

export const getUsersAffiliateCode = async () => {
  return await queryApi('/affiliate-code/usercode')
}

export const getTodayDailyDeliveryQuestions =
  () => async (): Promise<{ id: number; questions: QuestionProps[] }> => {
    return await queryApi('/question/daily')
  }

export const getDailyDeliveryCurrentWeekIds = () => async () => {
  return await queryApi('/daily-delivery')
}

export const getExplanation = (id: number) => async (): Promise<Explanation> => {
  return await queryApi('/explanation', { id })
}

export const getUsersDailyDeliveryStatus = () => async () => {
  const response: (DailyDeliveryStatus | null)[] = await queryApi('/daily-status')
  return response
}

export const getUser = () => async () => {
  const response: CarepartnerUser = await queryApi('/user')
  return response
}
