import { useEffect, useState } from 'react'

import BexContext from '../core/BexContext'
import { fetchInit } from '../core/fetch'
import { getCookieUnitId, setCookieUnitId } from '../core/util'
import { BexState } from '../core/interface'

type ExperimentFetchMode = 'preload' | 'onDemand'

const BexProvider = ({
  children,
  experimentFetchMode = 'onDemand',
}: {
  children: React.ReactNode
  experimentFetchMode?: ExperimentFetchMode
}) => {
  const [bexState, setBexState] = useState<BexState>({
    init: false,
    status: 'ready',
    mode: 'onDemand',
    unitId: '',
    userId: '',
    pick: {},
  })

  useEffect(() => {
    const unitId = getCookieUnitId()
    setBexState((bexState) => ({ ...bexState, status: 'loading' }))
    fetchInit({ unitId, experimentFetchMode })
      .then((res) => {
        if (res.statusCode === 200) {
          const { data } = res
          const { pick } = data
          const { unitId: _unitId } = data?.unitInfo || {}
          setBexState((bexState) => ({
            ...bexState,
            init: experimentFetchMode === 'preload',
            ready: 'success',
            mode: experimentFetchMode,
            unitId: _unitId,
            pick,
          }))

          if (!unitId) {
            setCookieUnitId(_unitId)
          }
        }
      })
      .catch((err) => {
        setBexState((bexState) => ({
          ...bexState,
          status: 'error',
        }))
        console.error('fetchInit error', err)
      })
  }, [])

  return <BexContext.Provider value={{ bexState, setBexState }}>{children}</BexContext.Provider>
}

export { BexProvider }
