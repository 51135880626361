// eslint-disable-next-line no-use-before-define
import React from 'react'
import Script from 'next/script'

export const KEY = process.env.NEXT_PUBLIC_KAKAO_PIXEL_KEY
export const KakaoDeveloperKey = process.env.NEXT_PUBLIC_KAKAO_DEVELOPER_KEY

export function renderKakaoPixelScript() {
  return (
    <>
      <Script
        strategy="afterInteractive"
        id="naver_conversion"
        dangerouslySetInnerHTML={{
          __html: `
            kakaoPixel('${KEY}').pageView();
          `,
        }}
      />
    </>
  )
}
