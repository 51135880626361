import React, { useEffect, useState } from 'react'
import { useEventLogging } from '@packages/jslib'

import { useBexState } from '../core/useBexState'
import { fetchTreatment } from '../core/fetch'
import { removeCookieUnitId, setCookieUnitId } from '../core/util'
import { UnitInfo } from './type'

interface PropsBexExperiment {
  className?: string
  experimentKey: string
  forceFetch?: boolean
  children: React.ReactNode | ((unitInfo: UnitInfo) => React.ReactNode)
}

interface ChildComponentProps {
  treatmentKey: string
  className?: string
  isExposed?: boolean
}

const process: string[] = []
const defaultUnitInfo: UnitInfo = { treatmentKey: 'A', isExposed: false }
const BexExperiment = ({ experimentKey, forceFetch, children, className }: PropsBexExperiment) => {
  const [unitInfo, setUnitInfo] = useState<UnitInfo | null>(null)
  const [matchedChild, setMatchedChild] = useState<React.ReactNode>(null)
  const [match, setMatch] = useState<'miss' | 'hit'>('miss')
  const { bexState, setBexState } = useBexState()
  const [hasFetch, setHasFetch] = useState<boolean>(false)
  const { setUserProperties } = useEventLogging()
  const setTreatmentFirstVariation = () => {
    setUnitInfo(defaultUnitInfo)
  }

  useEffect(() => {
    const { status, unitId, userId, pick } = bexState
    if (status === 'error') {
      setUnitInfo({
        treatmentKey: 'A',
        isExposed: true,
      })
    }

    if (!unitId) {
      return
    }

    const checkAndFetch = async () => {
      // process 배열에 experimentKey가 없을 경우
      if (!process?.find((k) => k === experimentKey)) {
        process.push(experimentKey)
        try {
          const res = await fetchTreatment(experimentKey, unitId, userId)
          if (res.statusCode === 200) {
            const { data } = res
            const { treatment, isExposed, unitId: newUnitId } = data?.unit || {}
            setHasFetch(true)
            setUnitInfo({
              treatmentKey: treatment.key,
              isExposed: isExposed,
            })
            if (newUnitId) setCookieUnitId(newUnitId)
            // set user property of amplitude
            setUserProperties({ properties: { [`experiment_:${experimentKey}`]: treatment.key } })
          } else {
            if (res.statusCode === 400 && res.message === 'not found unit') {
              removeCookieUnitId()
            }
            setTreatmentFirstVariation()
          }
        } catch (error) {
          console.error(`Error during fetch of experiment ${experimentKey}:`, error)
        } finally {
          process.splice(process.indexOf(experimentKey), 1)
        }
      } else {
        setTimeout(checkAndFetch, 50)
      }
    }

    if (bexState.mode === 'preload' && pick?.[experimentKey]?.treatmentKey && !forceFetch) {
      setUnitInfo({
        treatmentKey: pick[experimentKey].treatmentKey,
        isExposed: pick[experimentKey].isExposed,
      })
      setHasFetch(true)
      return
    } else if (
      (bexState.mode !== 'preload' && !pick?.[experimentKey]?.treatmentKey) ||
      forceFetch
    ) {
      checkAndFetch()
    } else if (pick?.[experimentKey]?.treatmentKey) {
      // 이미 fetch한 경우 bexState에 있는 값을 사용
      setUnitInfo({
        treatmentKey: pick?.[experimentKey]?.treatmentKey,
        isExposed: pick?.[experimentKey]?.isExposed,
      })
      setHasFetch(true)
    }
  }, [experimentKey, bexState])

  useEffect(() => {
    if (!unitInfo) return
    // check children is function
    if (typeof children === 'function') {
      setMatchedChild(children(unitInfo))
      return
    }

    let _matchedChild: React.ReactNode = null

    React.Children.toArray(children).some((child) => {
      if (
        React.isValidElement<ChildComponentProps>(child) &&
        child.props.treatmentKey === unitInfo.treatmentKey
      ) {
        _matchedChild = React.cloneElement(child, { isExposed: unitInfo.isExposed })

        // 최초 노출 시 pick에 저장
        if (bexState.pick && !bexState.pick[experimentKey]) {
          const pick = {
            ...bexState.pick,
            [experimentKey]: {
              treatmentKey: unitInfo.treatmentKey,
              isExposed: unitInfo.isExposed,
            },
          }
          setBexState((bexState) => ({
            ...bexState,
            pick,
          }))
        }
        return true
      }
      return false
    })

    if (_matchedChild && hasFetch) setMatch('hit')
    else _matchedChild = React.Children.toArray(children)[0]

    setMatchedChild(_matchedChild)
  }, [unitInfo, hasFetch, children])

  if (!unitInfo) return null

  return (
    <div className={`bex ${experimentKey} ${className}`} data-bex-matched={match}>
      {matchedChild}
    </div>
  )
}

export { BexExperiment }
