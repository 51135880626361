import { createContext } from 'react'

import { BexState } from './interface'

type BexContextState = {
  bexState: BexState
  setBexState: React.Dispatch<React.SetStateAction<BexState>>
}

const BexContext = createContext<BexContextState>({
  bexState: {
    init: false,
    status: 'ready',
    mode: 'onDemand',
    unitId: '',
    userId: '',
    pick: {},
  },
  setBexState: () => {
    return
  },
})

export default BexContext
