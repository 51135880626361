import * as amplitude from '@amplitude/analytics-browser'
import { Identify } from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'

declare global {
  var flutter_inappwebview: any
}

export type RevenueProps = {
  productId?: string
  quantity?: number
  price: number
  revenueType: string
  eventProperties: any
}

async function initSessionReplay() {
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 0.1,
  })
  amplitude.add(sessionReplayTracking)
}
export class AmplitudeEventLogger {
  private static instance: AmplitudeEventLogger

  private constructor() {
    if (typeof window !== 'undefined') {
      initSessionReplay()
      amplitude.init(
        process.env.NEXT_PUBLIC_AMPLITUDE_KEY as string,
        undefined,
        {
          defaultTracking: {
            sessions: true,
          },
        }
      )
    }
  }

  static getInstance() {
    if (!AmplitudeEventLogger.instance) {
      AmplitudeEventLogger.instance = new AmplitudeEventLogger()
    }
    return AmplitudeEventLogger.instance
  }

  getUserId() {
    return amplitude.getUserId()
  }

  setUserId(userId: string | undefined) {
    amplitude.setUserId(userId)
    if (
      window?.flutter_inappwebview &&
      typeof window?.flutter_inappwebview.callHandler === 'function'
    )
      window.flutter_inappwebview.callHandler('setUserId', userId ?? null)
  }

  setUserProperties(properties: object | null) {
    if (properties && typeof properties === 'object') {
      const identify = new Identify()
      Object.entries(properties).forEach(([key, value]) => {
        identify.set(key, value)
      })
      amplitude.identify(identify)
      if (
        window?.flutter_inappwebview &&
        typeof window?.flutter_inappwebview.callHandler === 'function' &&
        properties
      ) {
        // 앱 로깅
        Object.entries(properties).map(([key, value]) => {
          window.flutter_inappwebview.callHandler('setUserProperty', key, value)
        })
      }
    }
  }

  track(name: string, properties?: object) {
    amplitude.logEvent(name, properties)
    if (
      window?.flutter_inappwebview &&
      typeof window?.flutter_inappwebview.callHandler === 'function'
    ) {
      // 앱 로깅
      // name이 38자를 초과할 경우, 40자까지만 사용합니다. firebase event analytics의 name이 40자만 허용하기 때문
      const trimmedName = name.length > 40 ? name.substring(0, 40) : name

      // properties 객체의 모든 값을 문자열로 변환
      const stringifiedProperties = properties
        ? Object.entries(properties).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value)
              return acc
            },
            {}
          )
        : {}

      window.flutter_inappwebview.callHandler(
        'logEvent',
        trimmedName,
        stringifiedProperties
      )
    }
  }

  trackButtonClick(name: string, properties?: object, pagePath?: string) {
    this.track(
      `[Button Click] ${name}`,
      Object.assign(
        { pagePath: pagePath || window.location.pathname },
        properties
      )
    )
  }

  trackAction(name: string, properties?: object, pagePath?: string) {
    this.track(
      `[Action] ${name}`,
      Object.assign(
        { pagePath: pagePath || window.location.pathname },
        properties
      )
    )
  }

  trackPageView(pagePath?: string, pageName?: string, properties?: object) {
    this.track(
      `[Page View] ${pageName ? pageName : 'Untaged'}`,
      Object.assign(
        { pagePath: pagePath || window.location.pathname },
        properties
      )
    )
  }

  trackShow(name: string, pagePath?: string, properties?: object) {
    this.track(
      `[Show] ${name ? name : 'Untaged'}`,
      Object.assign(
        { pagePath: pagePath || window.location.pathname },
        properties
      )
    )
  }

  trackRevenue(revenuProps: RevenueProps) {
    const { productId, quantity, price, revenueType, eventProperties } =
      revenuProps
    let revenue = new amplitude.Revenue()
    if (productId) revenue = revenue.setProductId(productId)
    if (quantity) revenue = revenue.setQuantity(quantity)
    revenue = revenue.setRevenueType(revenueType)
    revenue = revenue.setPrice(price)
    revenue = revenue.setEventProperties(eventProperties)
    amplitude.revenue(revenue)
  }
}
