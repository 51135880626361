import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const setClientAuthorization = (token?: string) => {
  apiClient.interceptors.request.use(
    function (config) {
      config.headers.Authorization = `Bearer ${token}`
      return config
    },
    function (error) {
      console.error(error)
      return Promise.reject(error)
    }
  )
}

export default apiClient
