import { useEffect, useMemo, useState } from 'react'
import { useEventLogging } from '@packages/jslib'

import { fetchInit, fetchTreatment } from '../core/fetch'
import { useBexState } from '../core/useBexState'
import { UnitInfo, FetchResponse, defaultUnitInfo } from './type'

const useBexFeature = (featureKey: string) => {
  const defaultFeatureUnitInfo = {
    isExposed: false,
  }

  const { bexState } = useBexState()
  const { unitId, userId } = bexState
  const [unitInfo, setUnitInfo] = useState<Pick<UnitInfo, 'isExposed'>>(defaultFeatureUnitInfo)

  useEffect(() => {
    fetchTreatment(featureKey, unitId, userId).then((res) => {
      if (res.statusCode === 200) {
        const { data } = res
        const { status } = data
        const { isExposed } = data?.unit || {}
        setUnitInfo({
          isExposed: status === 'running' && isExposed,
        })
      } else {
        setUnitInfo(defaultUnitInfo)
      }
    })
  }, [featureKey])

  return unitInfo
}

const useBexExperiment = (
  experimentKey: string,
  options: { enable: boolean } = { enable: true }
) => {
  const { bexState } = useBexState()
  const { unitId, userId, pick } = bexState
  const [enable, setEnable] = useState<boolean>(options.enable)
  const [unitInfo, setUnitInfo] = useState<UnitInfo>(defaultUnitInfo)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const { setUserProperties } = useEventLogging()

  const callFetchTreatment: () => Promise<UnitInfo> | undefined = () => {
    if (!unitId) return
    if (pick?.[experimentKey]?.groupingCriteria === 'user' && !userId) return

    return new Promise((resolve) => {
      fetchTreatment(experimentKey, unitId, userId).then((res) => {
        if (res.statusCode === 200) {
          const { data } = res
          if (data?.unit) {
            const { treatment, isExposed } = data.unit
            setUnitInfo({
              treatmentKey: treatment?.key,
              isExposed,
            })
            setTimeout(() => setIsLoaded(true), 0)
            setUserProperties({ properties: { [`experiment_:${experimentKey}`]: treatment.key } })
            resolve({ treatmentKey: treatment.key, isExposed })
          }
        } else {
          setUnitInfo(defaultUnitInfo)
          resolve(defaultUnitInfo)
        }
      })
    })
  }

  const fetch: () => Promise<FetchResponse> | undefined = () => {
    return callFetchTreatment()?.then((unitInfo) => {
      return {
        unitInfo,
        isTreatmentGroup: unitInfo.treatmentKey === 'B',
        isControlGroup: unitInfo.treatmentKey === 'A',
      }
    })
  }

  const _setEnable = (status: boolean) => {
    setEnable(status)
  }

  useEffect(() => {
    if (!enable) return

    callFetchTreatment()
  }, [experimentKey, unitId, pick, userId, enable])

  // 아래 두 변수는 일반적으로 A/B 테스트를 할 때, 통제그룹은 A, 실험그룹은 B로 하기에 만들어진 변수
  // ABC 테스트 등 다른 예외 케이스 경우에 혼돈해서 사용해서는 안 됨
  const isControlGroup = useMemo(() => {
    return unitInfo.treatmentKey === 'A'
  }, [unitInfo])

  const isTreatmentGroup = useMemo(() => {
    return unitInfo.treatmentKey === 'B'
  }, [unitInfo])

  return { unitInfo, isLoaded, isControlGroup, isTreatmentGroup, fetch, setEnable: _setEnable }
}

const usePreload = () => {
  const { bexState, setBexState } = useBexState()
  const [ready, setReady] = useState(false)
  useEffect(() => {
    if (!ready) return
    fetchInit({
      unitId: bexState.unitId,
      userId: bexState.userId,
      experimentFetchMode: 'preload',
    })
      .then((res) => {
        if (res.statusCode === 200) {
          const { data } = res
          const { pick } = data
          const { unitId: _unitId } = data?.unitInfo || {}

          setBexState({
            ...bexState,
            init: true,
            unitId: _unitId,
            pick,
          })
        }
      })
      .catch((err) => {
        console.log('fetchInit error', err)
      })
  }, [ready])

  const preload = () => {
    setReady(true)
  }

  return { preload }
}

const useBexUser = () => {
  const { setBexState } = useBexState()

  const setBexUserId = ({ userId }: { userId: string }) => {
    setBexState((bexState) => {
      return { ...bexState, userId }
    })
  }

  return { setBexUserId }
}

const useBexEffect = (effect: () => void, experimentKey: string) => {
  const { bexState } = useBexState()
  const { pick } = bexState

  useEffect(() => {
    if (pick?.[experimentKey]) {
      effect()
    }
  }, [bexState])
}

const useBexPick = () => {
  const { bexState, setBexState } = useBexState()
  const { pick } = bexState

  const setPick = (pick: Record<string, any>) => {
    setBexState((bexState) => {
      return { ...bexState, pick }
    })
  }

  return { pick, setPick }
}

export { useBexExperiment, useBexUser, useBexFeature, usePreload, useBexEffect, useBexPick }
