// eslint-disable-next-line no-use-before-define
import React from 'react'
import Script from 'next/script'

const GAKey = process.env.NEXT_PUBLIC_GA_KEY
const GTMKey = process.env.NEXT_PUBLIC_GTM_KEY
const GoogleAdsKey = process.env.NEXT_PUBLIC_GADS_KEY

export function track(event: string, params = {}) {
  ;(window as any).dataLayer = (window as any).dataLayer || []
  function gtag() {
    ;(window as any).dataLayer.push(arguments)
  }
  // @ts-expect-error: 구글 애널리틱스
  gtag('event', event, params)
}

export function trackPageView({ pagePath }: any) {
  const payload: { page_path: string; debug_mode?: boolean } = {
    page_path: pagePath,
  }

  // debug_mode=false 인 경우에도 GA에서 debug_mode로 인식하는 것으로 보임!?
  if (process.env.NODE_ENV !== 'production') {
    payload.debug_mode = true
  }

  // @ts-expect-error: 구글 애널리틱스
  window.gtag('config', GAKey, payload)
}

export function trackConversion(conversionKey: string) {
  track('conversion', {
    send_to: `${GoogleAdsKey}/${conversionKey}`,
  })
}

export function renderGAScript() {
  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GAKey}`}
        strategy="afterInteractive"
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              var _ga_payload = {
                page_path: window.location.pathname
              };
              ${
                process.env.NODE_ENV !== 'production' &&
                '_ga_payload.debug_mode = true;'
              }
              gtag('config', '${GAKey}', _ga_payload);
            `,
        }}
      />
    </>
  )
}

export function renderGTMScript() {
  return (
    <>
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTMKey}');
          `,
        }}
      />
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${GTMKey}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </>
  )
}

export function renderGoogleADSScript() {
  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GoogleAdsKey}`}
        strategy="afterInteractive"
      />
      <Script
        id="google_ads"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${GoogleAdsKey}');
          `,
        }}
      />
    </>
  )
}
