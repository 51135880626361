import React from 'react'

interface PropsBexTreatment {
  treatmentKey: string
  isExposed?: boolean
  children: React.ReactNode
}

interface ChildComponentProps {
  isExposed?: boolean
}

const BexTreatment = ({ isExposed, children }: PropsBexTreatment) => {
  let _matchedChild = null
  React.Children.toArray(children).some((child) => {
    if (React.isValidElement<ChildComponentProps>(child)) {
      _matchedChild = React.cloneElement(child, { isExposed })
      return true
    }

    if (typeof child === 'string') {
      _matchedChild = child
      return true
    }
  })

  return <>{_matchedChild}</>
}

export { BexTreatment }
