// eslint-disable-next-line no-use-before-define
import React from 'react'
import Script from 'next/script'
import { useRouter } from 'next/router'

const NaverKey = process.env.NEXT_PUBLIC_NAVER_CV_KEY
const MagazineNaverKey = process.env.NEXT_PUBLIC_MAGAZINE_NAVER_CV_KEY

export function RenderWCSScript() {
  const router = useRouter()
  const isMagazine = router.pathname.startsWith('/magazines')

  return (
    <>
      <Script
        strategy="afterInteractive"
        id="naver_conversion"
        dangerouslySetInnerHTML={{
          __html: `
            if(!wcs_add) var wcs_add = {}; 
            wcs_add["wa"] = "${isMagazine ? MagazineNaverKey : NaverKey}";
              
            if (!_nasa) var _nasa={};
            if(window.wcs){
              wcs.inflow();
              wcs_do(_nasa);
            }
          `,
        }}
      />
    </>
  )
}
