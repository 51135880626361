import { useEffect, useState } from 'react'

import { getCookie } from '@lib/simpleFn'

type User = {
  status?: string
  expectedAcquisition?: string
}

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState<User>()
  useEffect(() => {
    setIsAuthenticated(!!getCookie('app_at'))
    const user = JSON.parse(getCookie('app_user') || '{}')
    setUser(user)
  }, [])
  return {
    isAuthenticated,
    user,
  }
}

export default useAuth
