export const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ')

export const setLocalStorage = (key: string, value: any) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    // catch possible errors:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  }
}

export const getLocalStorage = (key: string, initialValue?: any) => {
  try {
    const value = window.localStorage.getItem(key)
    return value !== null ? JSON.parse(value) : initialValue
  } catch (e) {
    // if error, return initial value
    return initialValue
  }
}

export const getCdnUrl = (fileName: string) => (process.env.NEXT_PUBLIC_CDN_URL ?? '') + fileName

export const getImageUrl = (fileName = '', ext = 'webp') => {
  if (fileName === '') return getCdnUrl('')
  const [name] = fileName.split('.')
  return getCdnUrl(`${name}.${ext}`)
}

// function that get name and return cookie
export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop()?.split(';').shift()
}
