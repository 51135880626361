// eslint-disable-next-line no-use-before-define
import React from 'react'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'

import { initialize as initializeFacebook } from './Facebook'
import {
  trackPageView as trackGAPageView,
  renderGAScript,
  renderGTMScript,
  renderGoogleADSScript,
} from './GoogleAnalytics'
import { KakaoDeveloperKey, renderKakaoPixelScript } from './Kakao'
import { RenderWCSScript } from './Naver'

// https://stackoverflow.com/questions/38906359/create-a-global-variable-in-typescript
/* tslint:disable */

declare global {
  var wcs: any
  var wcs_do: any
  var dataLayer: any
  var toggleUIDebugMode: () => void
  var checkUIDebugMode: () => void
  var Kakao: any
  var kakao: any
  // var naver: any
  var kakaoPixel: any
}
/* tslint:enable */

export const AnalyticsHOC = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter()

  const handleRouteChange = (url: string) => {
    // GA
    trackGAPageView({ pagePath: url })
  }

  useEffect(() => {
    // Facebook
    initializeFacebook(router)

    // Kakao
    try {
      if (!window?.Kakao?.isInitialized?.() && window?.Kakao?.init) {
        window.Kakao.init(KakaoDeveloperKey)
      }
    } catch (e) {
      Sentry.captureException(e)
    }

    // Google
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      {renderGAScript()}
      {renderGTMScript()}
      {renderGoogleADSScript()}
      {RenderWCSScript()}
      {renderKakaoPixelScript()}
      {children}
    </>
  )
}
