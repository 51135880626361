/* eslint-disable import/no-anonymous-default-export */
export default {
  openGraph: {
    url: process.env.NEXT_PUBLIC_CBT_URL,
    type: 'website',
    locale: 'ko_KR',
    site_name: '케어파트너 - 요양보호사 자격증 무료 CBT 모의고사',
    title: '케어파트너 - 요양보호사 자격증 무료 CBT 모의고사',
    images: [
      {
        url: 'https://cbt.carepartner.kr/images/og-image.png',
        width: 800,
        height: 400,
        alt: 'Og Image Alt',
      },
    ],
  },
}
