import { useContext } from 'react'

import BexContext from './BexContext'

const useBexState = () => {
  const context = useContext(BexContext)
  if (!context) {
    throw new Error('useBexState must be used within a BexProvider')
  }

  return context
}

export { useBexState }
