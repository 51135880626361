import { fetchTreatmentByUserId } from '../core/fetch'

type TreatmentForUserParam = {
  experimentKey: string
  userId: string
}

const getTreatmentForUserAsync = async ({ experimentKey, userId }: TreatmentForUserParam) => {
  const res = await fetchTreatmentByUserId(experimentKey, userId)
  if (res.statusCode === 200) {
    const { data } = res
    return data?.unit
  }
}

export { getTreatmentForUserAsync }
