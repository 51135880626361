import { AxiosError } from 'axios'

import apiClient from '@lib/apiClient'
import { getCookie } from '@lib/simpleFn'
import { setClientAuthorization } from '@lib/apiClient'

const carepartnerUrl = process.env.NEXT_PUBLIC_CAREPARTNER_URL ?? 'https://carepartner.kr'

export const queryApi = async (
  path: string,
  body?: string | string[][] | Record<string, any> | URLSearchParams | undefined,
  errorMessage?: string
) => {
  try {
    // todo : refactoring
    const appAt = getCookie('app_at')
    if (appAt) setClientAuthorization(appAt)
    const response = await apiClient({
      url: path + `?${new URLSearchParams(body).toString()}`,
      method: 'GET',
    })

    if (response.status === 200) {
      return await response.data
    } else {
      throw new Error(errorMessage != null ? errorMessage : `Failed: ${response?.status}`)
    }
  } catch (e) {
    if ((e as AxiosError)?.response?.status === 401) {
      // window.location.href = `${carepartnerUrl}/login?prevPath=${window.location.href}`
      // cbt 메인으로 접근 시 케어파트너의 cbt랜딩 페이지로 리디렉션한다.
      window.location.href = `${carepartnerUrl}/cbt_landing`
    }
  }
}

export type RequestMutateMethod = 'POST' | 'PATCH' | 'PUT' | 'DELETE'

export const mutateApi = async (
  path: string,
  method: RequestMutateMethod,
  body?: Record<string, any>,
  errorMessage?: string
) => {
  try {
    const appAt = getCookie('app_at')
    if (appAt) setClientAuthorization(appAt)
    const response = await apiClient({
      url: path,
      method,
      data: body,
    })

    if (response.status === 200 || response.status === 201) {
      return await response.data
    } else {
      throw new Error(errorMessage != null ? errorMessage : `Failed: ${response?.status}`)
    }
  } catch (e) {
    if ((e as AxiosError)?.response?.status === 401) {
      window.location.href = `${carepartnerUrl}/cbt_landing?redirect_url=${window.location.href}`
    }
  }
}
