type UnitInfo = {
  treatmentKey?: string
  isExposed: boolean
}

const defaultUnitInfo: UnitInfo = {
  treatmentKey: 'A',
  isExposed: false,
}

type FetchResponse = {
  unitInfo: UnitInfo
  isTreatmentGroup: boolean
  isControlGroup: boolean
}

export type { UnitInfo }
export type { FetchResponse }
export { defaultUnitInfo }
